<template>
	<article v-if="message" class="message is-warning">
		<div class="message-body" v-html="message">
		</div>
	</article>
</template>
<script>
	export default {
		props : ['message']
	}
</script>